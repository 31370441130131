// @ts-ignore
import DTLogo from '@dt/brand/logo.png';
import { Box, Stack } from '@mui/material';
import React from 'react';
import ImageLoaderPlaceholder from '../ImageLoaderPlaceholder';
import Text from '../Text';
const LoginError = () => {
    return (React.createElement(Box, { bgcolor: "white", height: "100vh" },
        React.createElement(Box, { alignItems: "center", display: "flex", height: "75%", justifyContent: "center" },
            React.createElement(Stack, { alignItems: "center", spacing: 2 },
                React.createElement(ImageLoaderPlaceholder, { alt: "DT-logo", src: DTLogo, width: "170px" }),
                React.createElement(Text, { component: "h1", variant: "titleL" }, "Error"),
                React.createElement(Text, null,
                    "An error was identified during your login process. Please contact",
                    ' ',
                    React.createElement(Text, { component: "span", variant: "link" },
                        React.createElement("a", { href: "mailto:info@datatheorem.com" }, "info@datatheorem.com")))))));
};
export default LoginError;
