import UsersIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/ExitToAppOutlined';
import HelpIcon from '@mui/icons-material/HelpOutline';
import ActivityIcon from '@mui/icons-material/History';
import NewReleaseIcon from '@mui/icons-material/NewReleases';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { useMutation } from '@apollo/client';
import AlertsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import PollIcon from '@mui/icons-material/Poll';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton, ListItemIcon, Menu, MenuItem, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
// @ts-ignore
import { ApolloLinkSchemaRestError } from '@dt/apollo-link-schema-rest';
// @ts-ignore Ignore JS files
import UserRole from '@dt/enums/UserRoleEnum';
import { invalidate_session } from '@dt/graphql-support/sevenhell/ts/users';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { ExtLink } from '@dt/material-components';
// @ts-ignore
import useGlobalStore from '@store/global';
const SettingsMenu = ({ anchorEl, handleClose }) => {
    const [error, setError] = useState(null);
    const { clearSessionAndRedirect } = useGlobalStore();
    const [shouldRemoveSesssion, setRemoveSession] = useState(false);
    const { loadingSession, userSession } = useGlobalStore();
    const [invalidateSession, { error: errorInvalidate }] = useMutation(invalidate_session, {
        onCompleted: () => {
            setRemoveSession(true);
        },
    });
    useEffect(() => {
        if (shouldRemoveSesssion && !errorInvalidate) {
            clearSessionAndRedirect();
        }
    }, [shouldRemoveSesssion, errorInvalidate]);
    useEffect(() => {
        if (errorInvalidate &&
            errorInvalidate.graphQLErrors?.[0]?.originalError instanceof ApolloLinkSchemaRestError &&
            // @ts-ignore
            errorInvalidate.graphQLErrors?.[0]?.originalError?.statusCode !== 204) {
            setError('There was an error signing out, please try again.');
        }
    }, [errorInvalidate]);
    if (loadingSession)
        return null;
    const currentUser = userSession?.current_user;
    return (React.createElement(Menu, { anchorEl: anchorEl, anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
        }, keepMounted: true, onClose: handleClose, open: Boolean(anchorEl) },
        error && (React.createElement(Snackbar, { anchorOrigin: { horizontal: 'center', vertical: 'bottom' }, autoHideDuration: 2000, open: Boolean(error) },
            React.createElement(Alert, { action: React.createElement(IconButton, { "aria-label": "close", color: "inherit", onClick: () => {
                        setError(null);
                    }, size: "small" },
                    React.createElement(CloseIcon, { fontSize: "inherit" })), severity: 'error', variant: "filled" }, error))),
        React.createElement(ExtLink, { "aria-label": "What's New Menu Item", onClick: handleClose, target: "_self", to: "/devsecops/v2/stanford-dish-security-university#whats-new" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(NewReleaseIcon, null)),
                "What's New")),
        currentUser?.role === UserRole.MANAGER && (React.createElement(ExtLink, { "aria-label": "Activity Menu Item", onClick: handleClose, target: "_self", to: "/management/v2/activity" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(ActivityIcon, null)),
                "Activity"))),
        React.createElement(ExtLink, { "aria-label": "Users Menu Item", onClick: handleClose, target: "_self", to: currentUser?.role === 'MANAGER' ? '/management/v2/users' : '/management/users/invite' },
            React.createElement(MenuItem, { disabled: !currentUser?.can_invite_users },
                React.createElement(ListItemIcon, null,
                    React.createElement(UsersIcon, null)),
                currentUser?.role === 'MANAGER' ? 'Manage ' : 'Invite ',
                "Users")),
        React.createElement(ExtLink, { "aria-label": "Preferences Menu Item", onClick: handleClose, target: "_self", to: "/management/v2/preferences" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(AlertsIcon, null)),
                "Alerts")),
        React.createElement(ExtLink, { "aria-label": "Shared Links Menu Item", onClick: handleClose, target: "_self", to: "/management/v2/shared-links" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(ShareIcon, null)),
                "Shared Links")),
        React.createElement(ExtLink, { "aria-label": "Compliance Report Menu Item", onClick: handleClose, target: "_self", to: "/management/v2/compliance-report" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(PollIcon, null)),
                "Compliance")),
        React.createElement(ExtLink, { "aria-label": "Profile Menu Item", onClick: handleClose, target: "_self", to: "/management/v2/profile" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(PersonIcon, null)),
                "Profile")),
        React.createElement(ExtLink, { "aria-label": "Help and Support Menu Item", onClick: handleClose, target: "_blank", to: "https://support.securetheorem.com/" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(HelpIcon, null)),
                "Help & Support")),
        React.createElement(MenuItem, { "aria-label": "Sign Out Menu Item", onClick: async () => {
                invalidateSession();
            } },
            React.createElement(ListItemIcon, null,
                React.createElement(LogoutIcon, null)),
            "Sign out")));
};
export default SettingsMenu;
