import setupLocatorUI from '@locator/runtime';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Application';

if (process.env.NODE_ENV === 'development') {
  setupLocatorUI();
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
