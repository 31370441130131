import { safeParse } from '@dt/functions';
import { Button, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import useGlobalStore from '@store/global';
import { differenceInCalendarDays, isAfter } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { dateFormats } from '../../apps/ahura/src/util/dateFormats';
import { formatDateDefault } from '../../apps/ahura/src/util/formatDateDefault';
import useCookie from './hooks/use_cookie';

const useStyles = makeStyles({
  snackbar: {
    width: '80%',
  },
});

export default (function TemporaryBroadcastMessage() {
  const classes = useStyles();
  const { userSession, loadingSession } = useGlobalStore();
  let accountName = '';

  if (!loadingSession && userSession) {
    accountName = userSession.account_info.name ?? '';
  }

  const BROADCAST_MESSAGE = {
    expires: '2022-11-16',
    hash: 'c7fa6ff47492e011a686218193275810',
    message: accountName
      ? `All ${accountName} iOS & Android apps, including all binaries with and without a subscription, have been tested for the OpenSSL Critical Vulnerability CVE-2022-3602/CVE-2022-3786 and NONE of them are vulnerable. Have a great rest of your day.`
      : `All iOS & Android apps, including all binaries with and without a subscription, have been tested for the OpenSSL Critical Vulnerability CVE-2022-3602/CVE-2022-3786 and NONE of them are vulnerable. Have a great rest of your day.`,
  };

  const [dismissed, setDismissed] = useState(false);

  const expiryDate = useMemo(() => {
    return formatDateDefault({ date: new Date(BROADCAST_MESSAGE.expires), formatStr: dateFormats.yyyyMMdd });
  }, [BROADCAST_MESSAGE.expires]);
  const daysToExpiry = useMemo(() => {
    return differenceInCalendarDays(new Date(expiryDate), new Date());
  }, [expiryDate]);

  const cookieKey = useMemo(() => {
    return BROADCAST_MESSAGE.hash ? `DT-${BROADCAST_MESSAGE.hash}` : '';
  }, [BROADCAST_MESSAGE.hash]);

  const [broadcastCookie, setBroadcastCookie] = useCookie(cookieKey);

  // No existing cookie for the current broadcast message
  useEffect(() => {
    if (typeof broadcastCookie === 'undefined') {
      const cookieValue = JSON.stringify({
        dismissed: false,
        expires: expiryDate,
      });

      daysToExpiry > 0
        ? setBroadcastCookie(cookieKey, cookieValue, { expires: daysToExpiry })
        : setBroadcastCookie(cookieKey, cookieValue);
    }
  }, [broadcastCookie, cookieKey, BROADCAST_MESSAGE.expires, expiryDate, setBroadcastCookie, daysToExpiry]);

  const broadcastCookieJSON = useMemo(() => {
    return safeParse(broadcastCookie);
  }, [broadcastCookie]);

  const dismissedOrExpiredCookie = useMemo(() => {
    return broadcastCookieJSON
      ? broadcastCookieJSON.dismissed || isAfter(new Date(), broadcastCookieJSON.expires)
      : false;
  }, [broadcastCookieJSON]);

  const handleDismissBroadcastMessage = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setDismissed(true);

      // Update the cookie to reflect that the message should be permanently dismissed
      const cookieValue = JSON.stringify({
        dismissed: true,
        expires: broadcastCookie ? JSON.parse(broadcastCookie)['expires'] : 0,
      });

      setBroadcastCookie(cookieKey, cookieValue, {
        expires: daysToExpiry > 0 ? daysToExpiry : 0,
      });
    },
    [cookieKey, setBroadcastCookie, broadcastCookie, daysToExpiry],
  );

  if (dismissed || dismissedOrExpiredCookie || daysToExpiry <= 0) {
    return null;
  }

  if (!BROADCAST_MESSAGE.message) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      className={classes.snackbar}
      onClose={handleDismissBroadcastMessage}
      open={!dismissed}
    >
      <Alert
        action={
          <Grid container direction={'row'} justifyContent={'flex-end'}>
            <Button
              aria-label={'Dismiss'}
              color="primary"
              onClick={handleDismissBroadcastMessage}
              size={'small'}
              variant="outlined"
            >
              Awesome
            </Button>
          </Grid>
        }
        onClose={handleDismissBroadcastMessage}
        severity="success"
        variant="standard"
      >
        {BROADCAST_MESSAGE.message}
      </Alert>
    </Snackbar>
  );
});
