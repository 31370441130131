import useLogin from '@hooks/login/useLogin';
import { AccountCircle, Lock } from '@mui/icons-material';
import { Box, Button, FormControlLabel, Stack, Switch, styled, useMediaQuery, useTheme } from '@mui/material';
import getEmailLocalStorage from '@utils/login/getEmailLocalStorage';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Message } from '..';
import DTNoBackgroundLogo from '../DTNoBackgroundLogo';
import FormInput from '../FormInput';
import PageContainer from '../PageContainer';
import Text from '../Text';
const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.brand[30],
                border: 0,
                opacity: 1,
            },
            color: '#fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        height: 16,
        margin: 2,
        width: 16,
    },
    '& .MuiSwitch-track': {
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(theme.palette.getContrastText(theme.palette.primary.main))}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(theme.palette.getContrastText(theme.palette.primary.main))}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::before, &::after': {
            content: '""',
            height: 16,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
        },
        borderRadius: 22 / 2,
    },
    padding: 8,
}));
const EMAIL_RULES = {
    pattern: {
        message: 'Invalid email format',
        value: /^\S+@\S+\.\S+$/i,
    },
    required: {
        message: 'Username is required',
        value: true,
    },
};
const OTP_RULES = {
    pattern: {
        message: 'Invalid One Time Password format',
        value: /^\d{6}$/i,
    },
    required: {
        message: 'One Time Password is required',
        value: true,
    },
};
const Login = () => {
    const initialEmail = getEmailLocalStorage();
    const theme = useTheme();
    const [rememberEmail, setRememberEmail] = useState(!!initialEmail);
    const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const { control, watch, formState, handleSubmit } = useForm({
        defaultValues: { email: initialEmail, otp: '' },
    });
    const { errors } = formState;
    const watchEmail = watch('email', initialEmail);
    const watchOtp = watch('otp', '');
    const { error, isLoading, submit, showOtp } = useLogin({
        email: watchEmail,
        otp: watchOtp,
        rememberEmail,
    });
    const handleChangeSwitch = (event) => {
        setRememberEmail(event.target.checked);
    };
    return (React.createElement(PageContainer, { fullWidth: true },
        React.createElement(Box, { alignItems: "center", display: "flex", height: "100vh", justifyContent: "center" },
            React.createElement(Box, { border: `1px solid ${theme.palette.gray[40]}`, borderRadius: 2, boxShadow: '0px 0px 57px 10px #0000001A', px: 8, py: 10, sx: { background: 'white' }, width: isLgScreen ? '35vw' : '80vw' },
                React.createElement("form", { onSubmit: handleSubmit(submit) },
                    React.createElement(Stack, { alignItems: "center", spacing: 3 },
                        React.createElement(Box, { width: '320px' },
                            React.createElement(DTNoBackgroundLogo, null)),
                        React.createElement(FormInput, { StartIcon: React.createElement(AccountCircle, { sx: { color: 'black' } }), control: control, error: !!errors.email, errorMessage: errors?.email?.message, id: "email", rules: EMAIL_RULES, title: "Username" }),
                        showOtp && (React.createElement(FormInput, { StartIcon: React.createElement(Lock, { sx: { color: 'black' } }), control: control, error: !!errors.otp, errorMessage: errors?.otp?.message, id: "otp", rules: OTP_RULES, title: "One Time Password" })),
                        React.createElement(Box, { width: "100%" },
                            React.createElement(Stack, { alignItems: "center", direction: "row", justifyContent: "space-between", mt: 1, width: "100%" },
                                React.createElement(Stack, { alignItems: "center", direction: "row", spacing: 1 },
                                    React.createElement(Text, { color: theme.palette.brand[30], variant: "titleXS" }, "Remember login"),
                                    React.createElement(FormControlLabel, { control: React.createElement(CustomSwitch, { checked: rememberEmail, onChange: handleChangeSwitch }), label: "" })),
                                React.createElement(Button, { color: "primary", disabled: isLoading, size: "small", sx: {
                                        height: 40,
                                        minWidth: 144,
                                        textTransform: 'none',
                                    }, type: "submit", variant: "contained" },
                                    React.createElement(Text, { color: "white", variant: "titleS" }, "Login")))),
                        error && (React.createElement(Box, { width: "100%" },
                            React.createElement(Message, { m: 0, message: error.message, variant: 'error' })))))))));
};
export default Login;
