import { ErrorOutline } from '@mui/icons-material';
import { FormControl, FormHelperText, InputAdornment, InputBase, InputLabel, Stack, styled } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import Text from './Text';
const StyledInputLabel = styled(InputLabel)({
    transform: 'none',
});
const StyledInput = styled(InputBase)(({ theme, error }) => ({
    '&.MuiInputBase-root': {
        backgroundColor: error ? theme.palette.red[50] : 'white',
        border: `0.1px solid ${error ? theme.palette.red[50] : theme.palette.gray[40]}`,
        borderRadius: theme.spacing(1),
        boxShadow: '0px 2px 4px 0px rgb(18 18 18 / 5%)',
        fontSize: 16,
        height: 40,
        padding: '10px 12px',
        position: 'relative',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    },
    '&.MuiInputBase-root.Mui-focused': {
        backgroundColor: error ? theme.palette.red[50] : theme.palette.gray[50],
    },
    'label + &': {
        marginTop: theme.spacing(3),
    },
}));
const FormInput = ({ title, error, rules, control, errorMessage, id, StartIcon }) => {
    return (React.createElement(Stack, { width: "100%" },
        React.createElement(FormControl, { error: !!error },
            React.createElement(StyledInputLabel, { htmlFor: "email", shrink: true, sx: { padding: '0 2px' } },
                React.createElement(Text, { variant: "titleXS" }, title)),
            React.createElement(Controller, { control: control, name: id, render: ({ field }) => (React.createElement(StyledInput, { id: id, ...field, error: error, fullWidth: true, placeholder: title, startAdornment: StartIcon ? React.createElement(InputAdornment, { position: "start" }, StartIcon) : null })), rules: rules }),
            error && (React.createElement(FormHelperText, { id: id },
                React.createElement(Stack, { alignItems: "center", direction: "row", spacing: 0.5 },
                    React.createElement(ErrorOutline, { sx: { fontSize: 16 } }),
                    React.createElement("p", null, errorMessage)))))));
};
export default FormInput;
