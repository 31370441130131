// @ts-ignore - no types for this
import dtConfig from '@dt/config';
import useGlobalStore from '@store/global';
import queryString from 'query-string';
const endpoints = {
    cloudy: dtConfig.cloudyBaseUrl,
    horizon: dtConfig.horizonApiBaseUrl,
    public: `${dtConfig.publicAPI}/apis/portal`,
    sevenhell: dtConfig.sevenhellApiBaseUrl,
};
const replaceValues = ({ url, endpoints }) => {
    let replacedEndpoint = url;
    for (const key in endpoints) {
        const pattern = `:${key}`;
        replacedEndpoint = replacedEndpoint.replace(pattern, endpoints[key]);
    }
    return replacedEndpoint;
};
const dtFetch = async ({ params, schema, url, variables }) => {
    const { sessionId } = useGlobalStore.getState();
    const fetchOptions = {
        headers: {
            authorization: `Session ${sessionId}`,
        },
    };
    let paramsUrl = '';
    let updatedUrlEndpoints = replaceValues({ endpoints, url });
    if (variables) {
        updatedUrlEndpoints = replaceValues({ endpoints: variables, url: updatedUrlEndpoints });
    }
    if (params) {
        paramsUrl = queryString.stringify(params);
    }
    const finalUrl = paramsUrl ? `?${paramsUrl}` : '';
    const r = await fetch(`${updatedUrlEndpoints}${finalUrl}`, fetchOptions);
    if (!r.ok) {
        const text = await r.text();
        const parsed = JSON.parse(text);
        const message = parsed?.error?.message || 'Network response was not ok.';
        if (r.status === 401) {
            window.location.href = `/login?forwardTo=${window.location.href}`;
        }
        if (r.status === 404) {
            throw new Error(`404: ${message}`);
        }
        throw new Error(message);
    }
    const jsonResponse = (await r.json());
    try {
        schema.parse(jsonResponse);
        return jsonResponse;
    }
    catch (e) {
        console.error(e);
        throw new Error('Invalid response format.');
    }
};
export default dtFetch;
