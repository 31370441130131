// sort-imports-ignore
import { getSortFn } from '@dt/findings/sort';
import { Raven } from '@dt/global';
import { withProgressIndicator } from '@dt/progress-indicator';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { stringFromParametricRequest } from '@dt/string';
import { get, list, patch, targets } from '@dt/user-api/security_findings';
import { call, put, spawn, take, takeEvery } from 'redux-saga/effects';
import { reportsPageLoadedAction } from '../actions/reportsActions';
import paginate, { paginateToEnd } from './util/paginate';

import {
  anErrorOccurred,
  exportButtonClicked,
  updateFindingError,
  updateFindingStarted,
  updateFindingSuccess,
  updateFindings,
} from '../actions';
import { ActionEnum } from '../actions/filterActions';
import {
  changePriority,
  linkedIssuesRoutine,
  securityFindingLightboxOpened,
  securityFindingOpened,
  securityFindingsFetchAllFinish,
  securityFindingsFetchAllStart,
  updatePermanentlyClosedStatus,
  updateStatus as updateStatusAction,
  updateStatusFailure,
  updateStatusStarted,
  updateStatusSuccess,
} from '../actions/securityFindings';
import { SecurityFindingEndpoint } from '../endpoints';
import { apps as appsSelector } from '../selectors/apps';
import { downloadZip } from '../services/reporting/reportwriter-server';

import tracking, { dataCreators } from '@dt/analytics';
import { callSaga, select } from '@dt/redux-saga-wrapped-effects';
import useGlobalStore from '@store/global';

function* loadFindings(params) {
  return yield* withProgressIndicator(
    function* () {
      try {
        const response = yield* callSaga(paginate, SecurityFindingEndpoint, params, params =>
          callPromise(list, params),
        );
        if (response.security_findings) {
          yield put(updateFindings(response.security_findings));
          return response.security_findings;
        }
      } catch (err) {
        console.error(err.stack);
        throw err;
      }
    },
    stringFromParametricRequest(SecurityFindingEndpoint, params),
  );
}

function* loadAllFindings(params) {
  return yield* paginateToEnd(loadFindings, SecurityFindingEndpoint, params, params);
}

/**
  When we want to fetch ALL findings, we should just do it once.
 */
function* loadAllFindingsWrapper() {
  yield put(securityFindingsFetchAllStart());
  yield* paginateToEnd(loadFindings, SecurityFindingEndpoint, {}, {});
  yield put(securityFindingsFetchAllFinish());
}

function* watchForReportsPageView() {
  yield take(reportsPageLoadedAction.toString());
  yield* callSaga(loadAllFindingsWrapper);
}

function* watchForClickSaveReport() {
  yield take(ActionEnum.SAVE_REPORT_CLICKED);
  yield* callSaga(loadAllFindingsWrapper);
}

export default function* securityFindingsWatchers() {
  yield spawn(watchForStatusUpdate);
  yield spawn(watchForPriorityChange);
  yield spawn(watchForExportRequests);
  yield spawn(watchForReportsPageView);
  yield spawn(watchForPermanentlyClosedStatusChange);
  yield spawn(watchForSecurityFindingDialogOpened);
  yield spawn(watchForSecurityFindingLightboxOpened);
  yield spawn(watchForClickSaveReport);
}

export function* updateStatus(findingId, targetId, newStatus) {
  yield put(updateStatusStarted(findingId, targetId));

  try {
    const resp = yield* callPromise(targets.statuses.create, findingId, targetId, { status: newStatus });

    const status = {
      date: resp.date,
      status: resp.status,
    };

    yield put(updateStatusSuccess(findingId, targetId, status));
  } catch (e) {
    if (e.result && e.result.error && e.result.error.code === 409) {
      // conflict, probably last call timed out so we had to revert back but server did the action and the user tried again
      // so now we need to fix the client
      yield put(
        updateStatusSuccess(findingId, targetId, {
          date: new Date().toString(),
          status: newStatus,
        }),
      );
      return;
    } else if (e.result && e.result.error && e.result.error.code) {
      // some other network error
      yield put(updateStatusFailure(findingId, targetId));
    } else {
      throw e;
    }
  }

  yield call(tracking, dataCreators.targetClosed(newStatus));
}

function* watchForStatusUpdate() {
  yield takeEvery(updateStatusAction.toString(), function* (action) {
    const { securityFindingId, targetId, newStatus } = action.payload;
    yield call(updateStatus, securityFindingId, targetId, newStatus);
  });
}

function* watchForPriorityChange() {
  yield takeEvery(changePriority.toString(), function* (action) {
    const { priority, finding } = action.payload;
    yield* withProgressIndicator(function* () {
      const newFinding = yield* callPromise(patch, finding.id, {
        priority: priority,
      });
      if (newFinding && newFinding.id === finding.id) {
        yield put(updateFindings([newFinding]));
      }

      yield call(tracking, dataCreators.priorityChange(finding.priority, priority));
    });
  });
}

function* watchForPermanentlyClosedStatusChange() {
  yield takeEvery(updatePermanentlyClosedStatus.toString(), function* (action) {
    yield put(updateFindingStarted(action.payload.finding));

    const { requestedAggregatedStatus, isPermanentlyClosed, finding } = action.payload;

    try {
      let newFinding;
      if (requestedAggregatedStatus != null && requestedAggregatedStatus !== finding.aggregated_status) {
        newFinding = yield* callPromise(patch, finding.id, {
          aggregated_status: requestedAggregatedStatus,
          is_permanently_closed: isPermanentlyClosed,
          priority: null,
        });
      } else {
        newFinding = yield* callPromise(patch, finding.id, {
          aggregated_status: null,
          is_permanently_closed: isPermanentlyClosed,
          priority: null,
        });
      }

      if (newFinding && newFinding.id === finding.id) {
        yield put(updateFindings([newFinding]));
        yield put(updateFindingSuccess(newFinding));
      }
    } catch (e) {
      Raven.captureException(e);

      yield put(updateFindingError(e));
      yield put(anErrorOccurred(`An error occurred while updating the finding: ${e.message}`));
    }
  });
}

function* watchForExportRequests() {
  yield takeEvery(exportButtonClicked.toString(), performExport);
}

function* performExport(action) {
  const apps = yield* select(appsSelector);
  const app = apps.find(app => app.id === action.payload);

  if (!app) {
    throw new Error('Could not find app');
  }

  const findings = yield* callSaga(loadAllFindings, { mobile_app_id: app.id });

  if (!findings) {
    throw new Error('Could not get findings for export');
  }

  const sortedFindings = getSortFn()(findings);

  const { userSession } = useGlobalStore.getState();

  const { current_user: currentUser, account_info: accountInfo } = userSession || {};

  if (!currentUser) {
    throw new Error('expected currentuser email');
  }

  const email = currentUser.login_email;

  yield* callPromise(downloadZip, {
    ...app,
    customer_name: accountInfo && accountInfo.name,
    email: email,
    security_finding_list: sortedFindings,
  });

  yield call(tracking, dataCreators.exportPerformed());
}

function* loadFinding(params) {
  const response = yield* callPromise(get, params.id);
  if (!response) {
    throw new Error('could not get finding in `loadFinding`');
  }

  yield put(updateFindings([response]));
}

function* watchForSecurityFindingDialogOpened() {
  yield takeEvery(securityFindingOpened.toString(), function* (action) {
    const { finding, linkedApps } = action.payload;

    try {
      yield put(
        linkedIssuesRoutine.request({
          issueTypeId: finding.issue_type_id,
          mobileAppId: finding.mobile_app_id,
        }),
      );

      if (linkedApps && linkedApps.length > 0) {
        const linkedFindings = [];
        for (const app of linkedApps) {
          // NOTE: This query returns a list of 1 item or empty list and consequently pagination is not needed.
          const linkedFindingResponse = yield* callPromise(list, {
            issue_type_id: finding.issue_type_id,
            mobile_app_id: app.id,
          });

          if (linkedFindingResponse && linkedFindingResponse.security_findings) {
            linkedFindings.push(linkedFindingResponse.security_findings[0]);
          }
        }

        if (linkedFindings.length > 0) {
          yield put(updateFindings(linkedFindings));
        }
      }

      yield put(
        linkedIssuesRoutine.success({
          issueTypeId: finding.issue_type_id,
          mobileAppId: finding.mobile_app_id,
        }),
      );
    } catch (e) {
      yield put(linkedIssuesRoutine.failure(e.toString()));
    } finally {
      yield put(linkedIssuesRoutine.fulfill({ findingId: finding.id }));
    }
  });
}

function* watchForSecurityFindingLightboxOpened() {
  yield takeEvery(securityFindingLightboxOpened.toString(), function* (action) {
    yield* callSaga(loadFinding, { id: action.payload });
  });
}
