import React, { useRef, useState } from 'react';
import { Skeleton } from '@mui/material';
const ImageLoaderPlaceholder = ({ src, alt, width = 24, height, style }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const imageRef = useRef(null);
    return (React.createElement(React.Fragment, null,
        !imageLoaded && (React.createElement(Skeleton, { height: height ?? width, style: { borderRadius: 8, marginRight: 8 }, variant: "rectangular", width: width })),
        React.createElement("img", { alt: alt, onLoad: () => {
                setImageLoaded(true);
            }, ref: imageRef, src: src, style: {
                ...style,
                display: imageLoaded ? 'block' : 'none',
            }, width: width })));
};
export default ImageLoaderPlaceholder;
