import Box from '@mui/material/Box';
import React from 'react';
const PageContainer = ({ children, fullWidth = true, large = false, bgWhite = false }) => {
    return (React.createElement(Box, { style: {
            backgroundColor: bgWhite ? 'white' : '#F4F5FC',
            margin: '0 auto',
            minHeight: '100vh',
            padding: '0 24px',
            ...(fullWidth ? {} : { maxWidth: large ? 1200 : 900 }),
        } }, children));
};
export default PageContainer;
