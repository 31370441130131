import { PolicyRuleTypeRelevanceValues } from '@dt/graphql-support/enums';
import { RelevanceTag } from '@dt/material-components';
import { Button, Menu, MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobalStore from '@store/global';
import React, { useState } from 'react';

/*
 * Allows the user to edit a policy rule's relevance.
 *
 * @param loading - Whether or not this component is loading.
 * @param disabled - Whether or not this component is disabled.
 * @param policy_rule_relevance - Current policy rule relevance.
 * @param policy_rule_type_title - Policy rule title.
 * @param policy_rule_type_default_relevance - Default policy rule type relevance.
 * @param onEditRelevanceClick - Event triggered when user edits the relevance.
 */
export const PolicyRuleTypesCardEditRelevanceButtonComponent = function PolicyRuleTypesCardEditRelevanceButton({
  loading,
  disabled,
  policy_rule_relevance,
  policy_rule_type_title,
  policy_rule_type_default_relevance,
  onEditRelevanceClick,
}) {
  const { userSession, loadingSession } = useGlobalStore();

  const [anchorEl, setAnchorEl] = useState(null);
  const shouldDisplayInformational = userSession?.account_info?.informational_relevance_enabled || false;

  const handleEditRelevanceItemClicked = relevance => {
    onEditRelevanceClick && onEditRelevanceClick(relevance);

    // Close the menu.
    setAnchorEl(null);
  };

  if (loadingSession) {
    return null;
  }

  const severities = [
    PolicyRuleTypeRelevanceValues.URGENT,
    PolicyRuleTypeRelevanceValues.IMPORTANT,
    PolicyRuleTypeRelevanceValues.PROACTIVE,
  ];

  if (shouldDisplayInformational) {
    severities.push(PolicyRuleTypeRelevanceValues.INFORMATIONAL);
  }

  return (
    <>
      <Button
        color="primary"
        disabled={loading || disabled}
        endIcon={loading && <CircularProgress aria-label={`Updating Severity ${policy_rule_type_title}`} size={15} />}
        onClick={e => setAnchorEl(e.currentTarget)}
        size="small"
      >
        Edit Severity
      </Button>
      <Menu
        anchorEl={anchorEl}
        disableScrollLock
        keepMounted
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
      >
        {severities.map(relevance => (
          <MenuItem
            key={relevance}
            onClick={() => handleEditRelevanceItemClicked(relevance)}
            selected={relevance === policy_rule_relevance}
          >
            <RelevanceTag relevance={relevance} />
            {relevance === policy_rule_type_default_relevance && <span style={{ marginLeft: 8 }}>(default)</span>}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const PolicyRuleTypesCardEditRelevanceButton = PolicyRuleTypesCardEditRelevanceButtonComponent;
