import React from 'react';
const DTNoBackgroundLogo = () => {
    return (React.createElement("svg", { viewBox: "0 0 275.996 64.181", width: "100%", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M41.029 35.381c0 3.186-1.122 5.902-3.366 8.148-2.247 2.244-4.963 3.365-8.15 3.365-3.188 0-5.904-1.121-8.149-3.365-2.246-2.246-3.368-4.963-3.368-8.148 0-3.188 1.122-5.904 3.368-8.149 2.245-2.245 4.961-3.368 8.149-3.368 2.485 0 4.679.677 6.58 2.03v-8.611h4.936v18.098zm-6.864 4.651c1.285-1.285 1.928-2.836 1.928-4.65 0-1.818-.643-3.369-1.928-4.654S31.329 28.8 29.513 28.8c-1.816 0-3.368.642-4.653 1.927-1.286 1.285-1.928 2.836-1.928 4.654 0 1.814.643 3.365 1.928 4.65 1.285 1.285 2.836 1.93 4.653 1.93 1.816 0 3.367-.644 4.652-1.929zM65.479 46.899h-4.937v-2.031c-1.901 1.354-4.096 2.031-6.582 2.031-3.187 0-5.903-1.123-8.148-3.369s-3.367-4.961-3.367-8.148c0-3.186 1.122-5.903 3.367-8.148s4.962-3.366 8.148-3.366c3.188 0 5.904 1.122 8.15 3.366 2.244 2.245 3.368 4.962 3.368 8.148v11.517zM53.96 28.801c-1.816 0-3.366.644-4.651 1.929-1.286 1.285-1.929 2.836-1.929 4.651 0 1.816.644 3.367 1.929 4.652 1.285 1.285 2.835 1.928 4.651 1.928 1.817 0 3.369-.643 4.655-1.928 1.285-1.285 1.927-2.836 1.927-4.652 0-1.815-.642-3.366-1.927-4.651-1.285-1.286-2.837-1.929-4.655-1.929zM101.907 46.899H96.97v-2.031c-1.901 1.354-4.096 2.031-6.582 2.031-3.187 0-5.903-1.123-8.148-3.369s-3.367-4.961-3.367-8.148c0-3.186 1.122-5.903 3.367-8.148s4.961-3.366 8.148-3.366c3.188 0 5.904 1.122 8.15 3.366 2.244 2.245 3.368 4.962 3.368 8.148v11.517zM90.389 28.801c-1.816 0-3.366.644-4.652 1.929-1.285 1.285-1.929 2.836-1.929 4.651 0 1.816.644 3.367 1.929 4.652 1.286 1.285 2.836 1.928 4.652 1.928 1.817 0 3.369-.643 4.654-1.928 1.286-1.285 1.928-2.836 1.928-4.652 0-1.815-.642-3.366-1.928-4.651-1.286-1.286-2.837-1.929-4.654-1.929zM71.83 28.8v6.582c0 1.814.644 3.365 1.928 4.65 1.286 1.285 2.836 1.93 4.653 1.93v4.934c-3.188 0-5.904-1.121-8.149-3.365-2.245-2.246-3.367-4.963-3.367-8.148v-18.1h4.935v6.581h6.581V28.8H71.83z", fill: "#144983", id: "data" }),
        React.createElement("g", { fill: "#929292", id: "theorem" },
            React.createElement("path", { d: "M136.501 46.905l-4.95-.018.05-13.852c0-2.318-1.895-4.213-4.223-4.213a4.228 4.228 0 0 0-4.223 4.222l-.051 13.861-4.95-.018.05-13.852c0-5.049 4.115-9.164 9.174-9.164s9.175 4.115 9.175 9.173l-.052 13.861zM109.211 28.8v6.582c0 1.814.644 3.365 1.929 4.65s2.836 1.93 4.651 1.93v4.934c-3.187 0-5.902-1.121-8.148-3.365-2.245-2.246-3.367-4.963-3.367-8.148v-18.1h4.935v6.581h6.58V28.8h-6.58zM189.605 35.381c0-3.188 1.122-5.904 3.367-8.149 2.246-2.245 4.962-3.368 8.149-3.368V28.8c-1.817 0-3.368.642-4.652 1.927-1.286 1.285-1.929 2.836-1.929 4.654v11.514h-4.936V35.381zM208.449 40.032c1.285 1.285 2.836 1.93 4.652 1.93 1.817 0 3.368-.645 4.653-1.93l.026-.025 3.495 3.496-.026.027c-2.245 2.244-4.961 3.365-8.148 3.365s-5.903-1.121-8.149-3.365c-2.245-2.246-3.367-4.963-3.367-8.148 0-3.188 1.122-5.904 3.367-8.149 2.246-2.245 4.962-3.368 8.149-3.368s5.903 1.123 8.148 3.368 3.368 4.96 3.368 8.149a8.94 8.94 0 0 1-.154 1.643h-17.736c.273 1.13.848 2.134 1.722 3.007zm9.306-9.305c-1.285-1.285-2.836-1.927-4.653-1.927-1.816 0-3.367.642-4.652 1.927-.428.43-.78.883-1.055 1.363h11.413a6.512 6.512 0 0 0-1.053-1.363z" }),
            React.createElement("path", { d: "M118.161 17.283h4.936v29.612h-4.936zM239.602 46.901l-.026-13.852a4.23 4.23 0 0 0-4.223-4.227 4.228 4.228 0 0 0-4.223 4.222l.027 13.847-4.951.01-.027-13.852c0-5.063 4.115-9.178 9.174-9.178 5.058 0 9.173 4.115 9.173 9.173l.027 13.847-4.951.01z" }),
            React.createElement("path", { d: "M253.049 46.901l-.027-13.852a4.23 4.23 0 0 0-4.223-4.227 4.229 4.229 0 0 0-4.223 4.222l.027 13.847-4.951.01-.027-13.852c0-5.063 4.115-9.178 9.174-9.178s9.175 4.115 9.175 9.173L258 46.891l-4.951.01zM183.868 27.232c-2.245-2.245-4.96-3.368-8.148-3.368-3.188 0-5.903 1.123-8.148 3.368-.229.229-.438.464-.643.703 0 0-12.103 12.134-12.856 12.854-1.029.984-2.341 1.17-3.755 1.17-1.236 0-2.352-.303-3.345-.908l11.487-11.487 1.055-1.055a8.973 8.973 0 0 0-1.053-1.272c-2.254-2.254-4.969-3.379-8.145-3.381-3.175.001-5.889 1.127-8.143 3.381-2.255 2.254-3.382 4.968-3.382 8.144.001 3.176 1.128 5.889 3.382 8.143 2.253 2.254 4.968 3.381 8.143 3.381 3.176 0 5.89-1.127 8.143-3.381.185-.184 1.934-1.973 3.485-3.557l8.808-8.887.238-.27c.026-.027.049-.056.076-.084 1.284-1.285 2.835-1.927 4.652-1.927 1.816 0 3.368.642 4.653 1.927 1.284 1.285 1.928 2.836 1.928 4.654 0 1.814-.644 3.365-1.928 4.65-1.285 1.285-2.837 1.93-4.653 1.93-1.817 0-3.368-.645-4.652-1.93a6.727 6.727 0 0 1-1.01-1.287l-3.55 3.551c.324.426.669.84 1.063 1.234 2.245 2.244 4.961 3.365 8.148 3.365 3.188 0 5.903-1.121 8.148-3.365 2.245-2.246 3.369-4.963 3.369-8.148.002-3.187-1.122-5.903-3.367-8.148zm-40.13 8.149c0-1.819.643-3.369 1.926-4.652 1.285-1.285 2.836-1.929 4.653-1.929a6.586 6.586 0 0 1 1.71.22l-8.071 8.071a6.502 6.502 0 0 1-.218-1.71z" }))));
};
export default DTNoBackgroundLogo;
