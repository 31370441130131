import UserRole from '@dt/enums/UserRoleEnum';
import UsersIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/ExitToAppOutlined';
import HelpIcon from '@mui/icons-material/HelpOutline';
import ActivityIcon from '@mui/icons-material/History';
import NewReleaseIcon from '@mui/icons-material/NewReleases';
import AlertsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import PollIcon from '@mui/icons-material/Poll';
import ShareIcon from '@mui/icons-material/Share';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import useGlobalStore from '@store/global';
import React, { memo } from 'react';
import ExtLink from './ExtLink';

const GlobalSettingsMenu = props => {
  const { anchorEl, handleClose } = props;
  const { userSession, clearSessionAndRedirect } = useGlobalStore();
  const currentUser = userSession.current_user;

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      anchorReference="anchorEl"
      disableScrollLock
      getContentAnchorEl={null}
      id="settings-menu"
      keepMounted
      onClose={handleClose}
      open={Boolean(anchorEl)}
    >
      <ExtLink
        aria-label="What's New Menu Item"
        onClick={handleClose}
        target="_self"
        to="/mobile/sdlc/stanford-dish-security-university"
      >
        <MenuItem>
          <ListItemIcon>
            <NewReleaseIcon />
          </ListItemIcon>
          What&apos;s New
        </MenuItem>
      </ExtLink>

      {currentUser.role === UserRole.MANAGER && (
        <ExtLink aria-label="Activity Menu Item" onClick={handleClose} target="_self" to="/management/activity">
          <MenuItem>
            <ListItemIcon>
              <ActivityIcon />
            </ListItemIcon>
            Activity
          </MenuItem>
        </ExtLink>
      )}

      <ExtLink
        aria-label="Users Menu Item"
        onClick={handleClose}
        target="_self"
        to={currentUser.role === 'MANAGER' ? '/management/users/' : '/management/users/invite'}
      >
        <MenuItem disabled={!currentUser.can_invite_users}>
          <ListItemIcon>
            <UsersIcon />
          </ListItemIcon>
          {currentUser.role === 'MANAGER' ? 'Manage ' : 'Invite '}Users
        </MenuItem>
      </ExtLink>

      <ExtLink aria-label="Preferences Menu Item" onClick={handleClose} target="_self" to="/mobile/preferences">
        <MenuItem>
          <ListItemIcon>
            <AlertsIcon />
          </ListItemIcon>
          Alerts
        </MenuItem>
      </ExtLink>

      <ExtLink aria-label="Shared Links Menu Item" onClick={handleClose} target="_self" to="/mobile/shared-links">
        <MenuItem>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          Shared Links
        </MenuItem>
      </ExtLink>

      <ExtLink
        aria-label="Compliance Report Menu Item"
        onClick={handleClose}
        target="_self"
        to="/management/compliance-report"
      >
        <MenuItem>
          <ListItemIcon>
            <PollIcon />
          </ListItemIcon>
          Compliance
        </MenuItem>
      </ExtLink>

      <ExtLink aria-label="Profile Menu Item" onClick={handleClose} target="_self" to="/management/profile">
        <MenuItem>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          Profile
        </MenuItem>
      </ExtLink>

      <ExtLink
        aria-label="Help and Support Menu Item"
        onClick={handleClose}
        target="_blank"
        to="https://support.securetheorem.com/"
      >
        <MenuItem>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          Help &amp; Support
        </MenuItem>
      </ExtLink>

      <MenuItem
        aria-label="Sign Out Menu Item"
        onClick={() => {
          clearSessionAndRedirect();
        }}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Sign out
      </MenuItem>
    </Menu>
  );
};

export default memo(GlobalSettingsMenu);
