import React from 'react';

import { default as ConfigurableLHSMenuComponent } from '@dt/material-components/navigation/ConfigurableLHSMenuPage';

const ConfigurableLHSMenu = props => {
  if (!props.configuration) return null;

  if (props.loading || !props.data) return <ConfigurableLHSMenuComponent isLoading />;

  return <ConfigurableLHSMenuComponent {...props} style={{ height: '100%', zIndex: 1150 }} />;
};

export default ConfigurableLHSMenu;
